import React, { createContext, useContext, useState } from 'react';

// Create the context
const SubheaderContext = createContext();

// Custom hook to use the Subheader context
export const useSubheader = () => useContext(SubheaderContext);

// Provider component
export const SubheaderProvider = ({ children }) => {
  const [links, setLinks] = useState([]);

  return (
    <SubheaderContext.Provider value={{ links, setLinks }}>
      {children}
    </SubheaderContext.Provider>
  );
};
