import axiosInstance from "../../utilities/axios_instance";

const apiUrl = process.env.REACT_APP_USERS_API;

// Fetch all users
const getUsers = () => {
  return axiosInstance.get(apiUrl);
};

// Fetch a single user
const getUser = (id) => {
  return axiosInstance.get(`${apiUrl}/${id}`);
};

// Create a user
const createUser = (userData) => {
  return axiosInstance.post(apiUrl, userData);
};

// Update a user
const updateUser = (id, userData) => {
  return axiosInstance.put(`${apiUrl}/${id}`, userData);
};

// Delete a user
const deleteUser = (id) => {
  return axiosInstance.delete(`${apiUrl}/${id}`);
};

const sendWelcomeEmail= (id) => {
  return axiosInstance.post(`${apiUrl}/${id}/send-welcome`);
}
export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
  sendWelcomeEmail,
};