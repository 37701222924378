import React, { useState } from 'react';
import { Button, Table, Alert, Form, Modal, Row, Col } from 'react-bootstrap';
import QRCode from 'react-qr-code';  // Import the QR code component
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import './Reports.css';

const MemberCardReport = () => {
    const apiUrl = process.env.REACT_APP_REPORT_API;
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        id: '',
        numSocio: '',
        firstname: '',
        lastname: '',
        email: ''
    });
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [qrMember, setQrMember] = useState(null); // Store member data for QR code

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage(null);
        setError(null);

        axiosInstance.post(`${apiUrl}/search_members`, formData)
            .then(response => {
                if (response.data.members && response.data.members.length > 0) {
                    setMembers(response.data.members);
                } else {
                    setMessage(t('search.no_results'));
                }
                setLoading(false);
            })
            .catch(error => {
                setError(t('search.error_fetching_members'));
                setLoading(false);
            });
    };

    const handleClear = () => {
        setFormData({
            id: '',
            numSocio: '',
            firstname: '',
            lastname: '',
            email: ''
        });
        setMembers([]);
        setMessage(null);
        setError(null);
    };

    const handleSendCard = (memberId) => {
        axiosInstance.post(`${apiUrl}/send_digital_card`, { memberId })
            .then(response => {
                if (response.data.success) {
                    setMessage(t('search.card_sent_successfully'));
                } else {
                    setError(t('search.failed_to_send_card'));
                }
            })
            .catch(error => {
                setError(t('search.error_sending_card'));
            });
    };

    const handleShowQrCode = (member) => {
        setQrMember(member); // Store the member data to generate the QR code
    };

    return (
        <div>
            <h1>{t('search.member_search_report')}</h1>

            <Form onSubmit={handleSearch} className='mb-4' inline="true">
                <Row className='mb-4'>
                    <Col>
                        <Form.Group controlId="formId">
                            <Form.Label>{t('search.member_id')}</Form.Label>
                            <Form.Control type="text" name="id" value={formData.id} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formNumSocio">
                            <Form.Label>{t('search.num_socio')}</Form.Label>
                            <Form.Control type="text" name="numSocio" value={formData.numSocio} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row><Row className='mb-4'>
                    <Col>
                        <Form.Group controlId="formFirstname">
                            <Form.Label>{t('search.firstname')}</Form.Label>
                            <Form.Control type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formLastname">
                            <Form.Label>{t('search.lastname')}</Form.Label>
                            <Form.Control type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row><Row className='mb-4'>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>{t('search.email')}</Form.Label>
                            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} />
                        </Form.Group>
                    </Col>
                </Row ><Row className='mr-auto mb-4'>
                    <Col className="d-flex justify-content-center">
                        <Button variant="primary" type="submit" disabled={loading} className="mr-2">
                            {t('search.search')}
                        </Button>
                        <Button variant="secondary" type="button" onClick={handleClear}>
                            {t('search.clear')}
                        </Button>
                    </Col>
                </Row>
            </Form>

            {loading && <p>{t('search.loading')}</p>}
            {error && <Alert variant="danger" dismissible="true">{error}</Alert>}
            {message && <Alert variant="success" dismissible="true">{message}</Alert>}

            {members.length > 0 && (
                <Table striped bordered hover className="mt-4">
                    <thead>
                        <tr>
                            <th>{t('search.member_id')}</th>
                            <th>{t('search.num_socio')}</th>
                            <th>{t('search.firstname')}</th>
                            <th>{t('search.lastname')}</th>
                            <th>{t('search.email')}</th>
                            <th>{t('search.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member) => (
                            <tr key={member.id}>
                                <td>{member.id}</td>
                                <td>
                                    <a href={`https://pianomeetups.com/members/member.html?mid=mpm${member.numSocio}`}
                                    target="_blank" rel="noreferrer">{member.numSocio} &nbsp;</a>
                                </td>
                                <td>{member.firstname}</td>
                                <td>{member.lastname}</td>
                                <td>{member.email}</td>
                                <td>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleSendCard(member.id)}
                                        className="mr-2"
                                    >
                                        {t('search.send_digital_card')}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => handleShowQrCode(member)}
                                    >
                                        {t('search.show_qr_code')}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            {/* QR Code Modal */}
            <Modal show={qrMember !== null} onHide={() => setQrMember(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('search.qr_code')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {qrMember && (
                        <QRCode value={`https://pianomeetups.com/members/member.html?mid=mpm${qrMember.numSocio}`} />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setQrMember(null)}>
                        {t('search.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MemberCardReport;
