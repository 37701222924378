import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './i18n.js';
import { Provider } from 'react-redux';
import Loader from './components/Loader.js';
import { store } from "./store";
import SpinnerOverlay from './components/SpinnerOverlay';
import { setLoading } from './store/setting/loadingSlice';

const container = document.getElementById('root');
const root = createRoot(container);
setLoading(false);
root.render(

<Suspense fallback={<Loader />}>
  <Provider store={store}>
      <BrowserRouter store={store} basename="/">
        <App />
        <SpinnerOverlay />
    </BrowserRouter>
  </Provider> 
</Suspense>
);
 