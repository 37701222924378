import React, { useEffect, useState } from 'react';
import { Button, Table, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import './Reports.css';  

const BirthdayReport = () => {
  const apiUrl = process.env.REACT_APP_REPORT_API;
  const { t } = useTranslation();

  const [birthdays, setBirthdays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Fetch the upcoming birthdays
    axiosInstance.get(`${apiUrl}/upcoming_birthdays`)
      .then(response => {
        if (response.data.birthdays && response.data.birthdays.length > 0) {
          setBirthdays(response.data.birthdays);
        } else {
          setMessage(response.data.message || t('birthday.no_upcoming_birthdays')); // Fallback to default message if no message from API
        }
        setLoading(false);
      })
      .catch(error => {
        setError(t('birthday.error_fetching_birthdays')); // Translate error message
        setLoading(false);
      });
  }, [apiUrl, t]);

  const handleSendBirthdayWish = (memberID) => {


    axiosInstance.post(`${apiUrl}/send_birthday_email`, { memberID})
      .then(response => {
        if (response.data.success) {
          setMessage(t('birthday.email_sent_successfully'));
        } else {
          setError(t('birthday.failed_to_send_email'));
        }
      })
      .catch(error => {
        setError(t('birthday.error_sending_email'));
      });
  };

  const isBirthdayThisWeek = (birthday) => {
    const today = new Date();
    const birthdayDate = new Date(birthday);
    const difference = (birthdayDate - today) / (1000 * 60 * 60 * 24);
    return difference >= 0 && difference <= 7;
  };

  if (loading) return <p>{t('birthday.loading')}</p>; // Translated loading text
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      <h1>{t('birthday.upcoming_birthdays')}</h1><br/>
      {message && <Alert variant="success" onClose={() => setMessage()} dismissible>{message}</Alert>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('birthday.member_id')}</th>
            <th>{t('birthday.num_socio')}</th>
            <th>{t('birthday.first_name')}</th>
            <th>{t('birthday.last_name')}</th>
            <th>{t('birthday.email')}</th>
            <th>{t('birthday.birthday')}</th>
            <th>{t('birthday.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {birthdays.length > 0 ? (
            birthdays.map((birthday) => {
              const isThisWeek = isBirthdayThisWeek(birthday.birthday);
              return (
                <tr
                  key={birthday.memberId}
                  className={isThisWeek ? 'due-now' : ''}
                >
                  <td>{birthday.memberId}</td>
                  <td>{birthday.numSocio}</td>
                  <td>{birthday.firstname}</td>
                  <td>{birthday.lastname}</td>
                  <td>{birthday.email}</td>
                  <td>{new Date(birthday.birthdate).toLocaleDateString()}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleSendBirthdayWish(birthday.memberId)}
                    >
                      {t('birthday.send_wish')}
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7">{message || t('birthday.no_upcoming_birthdays')}</td>
            </tr>
          )}
        </tbody>
      </Table>
      <legend> <ul style={{listStyleType: "square"}}>
                <li className="due-now">{t("birthday.this_week")}</li>
                <li>{t("birthday.upcoming_weeks")}</li>
                </ul></legend>
    </div>
  );
};

export default BirthdayReport;
