// languages.js
import en_flag from '../assets/img/flags/en.svg';
import es_flag from '../assets/img/flags/es.svg';
import fr_flag from '../assets/img/flags/fr.svg';

const languages = [
    { code: 'en', name: 'English', flag: en_flag },
    { code: 'es', name: 'Spanish', flag: es_flag },
    { code: 'fr', name: 'French', flag: fr_flag },
  ];
  
  export default languages; 