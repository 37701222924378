import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import SignIn from './views/auth/sign-in';
import Error404 from './views/errors/error404';
import Error500 from './views/errors/error500';
import Maintenance from './views/errors/maintenance';
import Default from './Default';
import Enlaces from './views/Links';
import { SubheaderProvider } from './components/SubheaderContext';
import Dashboard from './views/Dashboard';
import Landing from './Landing';
import Logout from './Logout';
import MemberForm from './views/members/MemberForm';
import MembersList from './views/members/MembersList';
import DueMembershipsList from './views/reports/DueMembershipsList';
import BirthdayReport from './views/reports/BirthdayReport';
import MemberCardReport from './views/reports/MemberCardReport';
import UploadMemberImage from './views/members/UploadMemberImage';
import MembersReport from './views/reports/MembersReport';
import LandingDashboard from './views/LandingDashboard';
import Dashboard404 from './views/Dashboard404';
import AttendanceForm from './views/members/AttendanceForm';
import ShowcasesForm from './views/members/ShowcasesForm';
import MembersInconsistencyReport from './views/reports/MembersInconsistencyReport';
import UserManagement from './views/users/UserManagement';
import NextEventReport from './views/reports/NextEventReport';
import GrantMemberBadge from './views/members/GrantMemberBadge';
//import SendMessages from './utilities/SendMessages';

function App() {
  const stillAuth = JSON.parse(window.localStorage.getItem("auth state"));
  const [isAuthenticated, setIsAuthenticated] = useState(stillAuth);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = process.env.REACT_APP_NAME;
    console.log("APP STARTED: "+process.env.REACT_APP_VERSION);

    if (isAuthenticated === null) {
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  const navigateToSignIn = () => {
    console.log("Trying to go to sign in");
    return navigate("login");
  };

  return (
    <SubheaderProvider>
        <Routes>
          {/*<Route path="/dashboard" element= {<Landing  setIsAuthenticated={setIsAuthenticated} />} /> */}
          <Route path="/" element={ <Default isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} onLoginRegister={navigateToSignIn} />} >
            <Route index element={ <Landing /> } />
            <Route path="dashboard"
                    element={isAuthenticated ? <Dashboard  isAuthenticated={isAuthenticated}  /> : <Navigate to="/login" />} >
              <Route index  element={<LandingDashboard />} />
              <Route path="new_member" element={<MemberForm />} />
              <Route path="new_member/:id" element={<MemberForm />} />
              <Route path="list_members" element={<MembersList />} />
              <Route path="edit_member/:id" element={<MemberForm />} />
              <Route path="due_memberships" element={<DueMembershipsList />} />
              <Route path="badges" element={<GrantMemberBadge />}/>
              <Route path="birthdays" element={<BirthdayReport />} />
              <Route path="member_card" element={<MemberCardReport />} />
              <Route path="upload_photo" element={<UploadMemberImage />} />
              <Route path="members_list" element={<MembersReport /> } />
              <Route path="attendance" element={<AttendanceForm /> } />
              <Route path="videos" element={<ShowcasesForm /> } />
              <Route path="badges" element={<Dashboard404 /> } />
              <Route path="errors" element={<MembersInconsistencyReport /> } />
              <Route path="users" element={<UserManagement /> } />
              <Route path="nextevent" element={<NextEventReport />}/>
            </Route>
            <Route path="enlaces" element={<Enlaces />} />
            <Route path="login" element={!isAuthenticated ? <SignIn setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/" />} setIsAuthenticated={setIsAuthenticated} />
            <Route path="links" element={ <Enlaces /> } />
            <Route path="new_member" element={<MemberForm />} />
          </Route>
          
            {/* <Route path="send" element={<SendMessages />} /> *(})
      
      {/*     <Route path="signin" element={!isAuthenticated ? <SignIn setIsAuthenticated={setIsAuthenticated} setUser={setUser}/> : <Navigate to="/" />} setIsAuthenticated={setIsAuthenticated}/>*/}
          <Route path="logout" element={<Logout setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="400" element={<Error404 />} />
          <Route path="500" element={<Error500 />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
    </SubheaderProvider>
  );
}

export default App;
