import React, { Fragment, useEffect } from 'react';
import { useSubheader } from './components/SubheaderContext'; 

const Landing = () => {
  const { setLinks } = useSubheader();

  useEffect(() => {
    setLinks([
    ]);

    // Cleanup on unmount
    return () => setLinks([]);
  }, [setLinks]);

  return (
    <Fragment>
      {/* Page Title 
      <!--div className="page-title" data-aos="fade">
        <div className="container">
          <h1>HOME</h1>
        </div>
      </div-->{/* End Page Title */}

      {/* Starter Section */}
      <section id="starter-section" className="starter-section section"  style={{backgroundColor:"#333"}}>
        <div className="container-fluid section-title row" data-aos="fade-up" style={{color:"white",alignItems:"center"}}> 
          <span>Bienvenid@<br /></span>
          
          <div className='col-5 text-center middle' >
          <h2>PianoMeetups Manager</h2>
          <p>Todo lo que ncesitas para gestionar PianoMeetups</p>
          </div>
          <div className='col-7'>
            <img src="images/bg_piano.jpg" style={{width:"300px"}} alt="background piano"/>
          </div>
        </div>
        <div className="container" data-aos="fade-up">
          <p>Use this page as a starter for your own custom pages.</p>
        </div>
      </section>{/* End Starter Section */}

    </Fragment>
  );
};

export default Landing;