// store/loadingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    clearLoading: (state) => {
      state.isLoading = false;
    },
    isLoading: (state) =>{
      return state.isLoading;
    }
  },
});

export const { setLoading, clearLoading, isLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
