import React, { Fragment, useEffect } from 'react';
import { useSubheader } from '../components/SubheaderContext'; 
import QRCode from 'react-qr-code';

const LandingDashboard = () => {
  const { setLinks } = useSubheader();


  useEffect(() => {
    setLinks([    ]);

    // Cleanup on unmount
    return () => setLinks([]);
  }, [setLinks]);

  return (
    <Fragment>
      <style>
        h2{"{color:#E42021}"}
      </style>

      <section id="form" className="second-section section">
        <div className="container section-title" data-aos="fade-up">
          <span>Miembros<br /></span>
          <h2>Nuevo miembro</h2>
          <p>El siguiente enlace/QR sirve para que los nuevos miembros puedan rellenar
            el formulario de forma online, sin tener que "picar" los datos luego
          </p>
          <br/>
          <QRCode value='./new_member' />

        </div>
        <div className="container text-center" data-aos="fade-up">
          <p>El formulario no da de alta directamente al usuario, pero si los crea en una base de
            datos temporal para facilitar su creación posterior.
          </p>
        </div>
      </section>{/* End Formulario */}

    </Fragment>
  );
};

export default LandingDashboard;