import React, { useState,  Fragment } from 'react';
import axiosInstance from '../../utilities/axios_instance';
import '../../i18n';
import { useTranslation } from 'react-i18next';

const AttendanceForm = () => {
  const { t } = useTranslation();
  const [attendanceData, setAttendanceData] = useState('');
  const [eventId, setEventId] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [resultSuccess, setResultSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiUrl = process.env.REACT_APP_MEMBERS_API;

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    if (!attendanceData || !eventId) {
      setResultMessage(t('Please provide both the event ID and attendance data.'));
      setResultSuccess(false);
      setIsSubmitting(false);
      return;
    }

    // Preparing the form data
    const formData = {
      eventId,
      attendance: attendanceData
    };

    try {
      // Sending the data to the backend API for processing
      const response = await axiosInstance.post(`${apiUrl}/submit_attendance`, formData);
      if (response.data.success) {
        setResultMessage(t('Attendance successfully submitted.'));
        setResultSuccess(true);
        if(response.data.error)
        {
            setResultMessage(t(response.data.message)+"<br/>"
                    +t('attendances.missing_meetupid')+":"+response.notFoundMembers);
        }
        else
        {
            setResultMessage(t('attendances.success') +":"+ response.data.message);
        }
        
      } else {
        setResultMessage(t('Error submitting attendance: ') + response.data.message);
        setResultSuccess(false);
      }
    } catch (error) {
      console.error('Error during submission:', error);
      setResultMessage(t('Error submitting attendance: ') + error.message);
      setResultSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Clearing the form data
  const handleClearForm = () => {
    setEventId('');
    setAttendanceData('');
    setResultMessage('');
    setResultSuccess(false);
  };

  return (
    <Fragment>
      <div className="container mt-5">
        <h1>{t('Attendance Form')}</h1>
        {resultMessage && (
          <div className={`alert ${resultSuccess ? 'alert-success' : 'alert-danger'}`} role="alert">
            <pre>{resultMessage}</pre>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="eventId" className="form-label">{t('Event ID')}</label>
            <input
              type="text"
              className="form-control"
              id="eventId"
              value={eventId}
              onChange={(e) => setEventId(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="attendanceData" className="form-label">{t('Attendance Data')}</label>
            <textarea
              className="form-control"
              id="attendanceData"
              rows="10"
              value={attendanceData}
              onChange={(e) => setAttendanceData(e.target.value)}
              placeholder={t('Enter attendance data in the format: meetupId\t[tab] guests_number\t[tab] join_date(optional)')}
              required
            ></textarea>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary me-3" disabled={isSubmitting}>
              {isSubmitting ? t('Submitting...') : t('Submit')}
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleClearForm} disabled={isSubmitting}>
              {t('Clear')}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default AttendanceForm;
