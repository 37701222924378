import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import '../../i18n';
import { useToken,useUser } from '../../store';
import axiosInstance from '../../utilities/axios_instance';


const MembersList = () => {
  const apiUrl = process.env.REACT_APP_TRANSIENT_MEMBERS_API;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'danger'
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const user = useUser(); // Get user information from context

  // Retrieve the token from local storage (or from Redux if applicable)
  const token = useToken().token;

  useEffect(() => {
    console.log(token);
    console.log(axiosInstance);
    axiosInstance.get(`${apiUrl}/get_members`,{
      headers: {
          'Content-Type': 'application/json',
      }})
      .then(response => {
        if (response.data.success) {
          setMembers(response.data.members);
        } else {
          setMessage(t('No members found.'));
          setMessageType('danger');
        }
        setLoading(false);
      })
      .catch(error => {
        setMessage(t('There was an error fetching the members:'));
        setMessageType('danger');
        setLoading(false);
      });
  }, [apiUrl, t,token]);

  const handleDelete = (id) => {
    axiosInstance.delete(`${apiUrl}/delete_member`,  {data: {id },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then(response => {
        if (response.data.success) {
          setMembers(members.filter(member => member.id !== id));
          setMessage(t('Member deleted successfully.'));
          setMessageType('success');
        } else {
          setMessage(t('Error deleting member.'));
          setMessageType('danger');
        }
      })
      .catch(error => { 
        setMessage(t('There was an error deleting the member.'+error.message));
        setMessageType('danger');
      });
    setShowModal(false);
  };

  const confirmDelete = (id) => {
    setModalType("delete");
    setSelectedMemberId(id);
    setShowModal(true);
  };
  const confirmTransfer = (id) => {
    setModalType("transfer");
    setSelectedMemberId(id);
    setShowModal(true);
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/edit_member/${id}`);
  };

  // Handle the transfer of a member
  const handleTransfer = (id) => {
    axiosInstance.post(`${apiUrl}/transfer_member`, { memberId: id })
      .then(response => {
        if (response.data.success) {
          // Remove the transferred member from the local state
          setMembers(members.filter(member => member.id !== id));
          setMessage(t('Member successfully transferred.'));
          setMessageType('success');
        } else {
          setMessage(t('Error transferring member.'));
          setMessageType('danger');
        }
      })
      .catch(error => {
        setMessage(t('There was an error transferring the member.')+": "+error.response.data.message);
        setMessageType('danger');
      });
      setShowModal(false);
  };

  const closeAlert = () => {
    setMessage('');
    setMessageType('');
  };

  return  user && ['Super', 'Manager'].includes(user.role) ? (
    <Fragment>
      <div className="pagetitle">
          <h1>{t("tempmember.list")}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item"><a href="/dashboard">{t("Dashboard")}</a></li>
              <li className="breadcrumb-item active">{t("tempmember.list")}</li>
            </ol>
          </nav>
        </div>
    <div className='container mt-5'>  
      {loading && <p>{t('Loading...')}</p>}
      {message && (
        <div className={`alert alert-${messageType} alert-dismissible fade show`} role="alert">
          {message}
          <button type="button" className="btn-close" onClick={closeAlert} aria-label="Close">
            &nbsp;
          </button>
        </div>
      )}
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{t('Alias')}</th>
            <th>{t('First Name')}</th>
            <th>{t('Last Name')}</th>
            <th>{t('Email')}</th>
            <th>{t('Phone')}</th>
            <th>{t('Category')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {members.map(member => (
            <tr key={member.id}>
              <td>{member.alias}</td>
              <td>{member.firstname}</td>
              <td>{member.lastname}</td>
              <td>{member.email}</td>
              <td>{member.phone}</td>
              <td>{member.category}</td>
              <td>
                <button className="btn btn-danger" onClick={() => confirmDelete(member.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <button className="btn btn-primary ms-2" onClick={() => handleEdit(member.id)}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className="btn btn-secondary ms-2" onClick={() => confirmTransfer(member.id)}>
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t(modalType.toString()+'.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t(modalType.toString()+'.body')}
         {/*  {t('Are you sure you want to delete this member? This action cannot be undone.')}*/}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button variant="danger" onClick={() => modalType === 'delete'? handleDelete(selectedMemberId) : handleTransfer(selectedMemberId)}>
            {t(modalType.toString()+'.action')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

    </Fragment>
    ) : (
      <div>
          <h1>{t('Access Denied')}</h1>
          <p>{t('You do not have permission to access this page.')}</p>
      </div>
  )
};

export default MembersList;
