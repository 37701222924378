import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../utilities/axios_instance';
import { setUser } from '../store/setting/userSlice';
import { useDispatch } from 'react-redux';

const UserProfilePopup = ({ show, handleClose, user }) => {
  const { t } = useTranslation();
  const [setErrors] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'danger'
  const apiUrl = process.env.REACT_APP_USERS_API;
  const dispatch = useDispatch();  // to dispatch actions to Redux store

  const [formData, setFormData] = useState({
    username: '',
    currentPassword:null,
    newPassword: null,
    confirmPassword: null,
    lang: 'es',
    name: '',
    email: '',
    role: ''
  });

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username,
        lang: user.lang || 'es',
        name: user.name,
        email: user.email,
        role: user.role
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      setMessageType('danger');
      setMessage(t('profile.password_no_match'));
      return;
    }
    handleSaveUserProfile();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    handleClose();
  } 
  const handleSaveUserProfile = async () => {
    try {
      
      const response = await axiosInstance.put(`${apiUrl}/update_profile`, formData);

      if (response.data.message) {
        // Success message
        setMessage(t('profile.profileUpdated'));
        setMessageType('success');
        user=response.data.user;
        dispatch(setUser(user));
        //handleClose();
      }
    } catch (error) {
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
        
        setMessage(error.response.data.errors.map((error, index) => `${index}: ${error.msg}\n`).join("\n"));
        setMessageType('danger');
      } else {
        console.log(error)
        setMessage("Network error");
        setMessageType('danger');
      }
    }
  };


  const closeAlert = () => {
    setMessage('');
};

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('profile.title')}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
        {message && (
            <div className={`alert alert-${messageType} alert-dismissible fade show`} role="alert">
              {message}
              <button type="button" className="btn-close" onClick={closeAlert} aria-label="Close">
                &nbsp;
              </button>
            </div>
          )}
          <Form.Group controlId="username">
            <Form.Label>{t('profile.username')}</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>{t('profile.name')}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>
          
          <Form.Group controlId="email">
            <Form.Label>{t('profile.email')}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="lang">
            <Form.Label>{t('profile.language')}</Form.Label>
            <Form.Select
              name="lang"
              value={formData.lang}
              onChange={handleChange} 
            >
              <option value="es">Español</option>
              <option value="en">English</option>
            </Form.Select>
          </Form.Group>
          <Form.Group controlId="currentPassword">
            <Form.Label>{t('profile.current_password')}</Form.Label>
            <Form.Control
              type="password"
              name="currentPassword"
              value={formData.currentPassword}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>{t('profile.new_password')}</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword">
            <Form.Label>{t('profile.confirm_password')}</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
            />
          </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>{t('profile.save')}</Button>
          <Button variant="secondary" onClick={handleCancel}>{t('profile.close')}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default UserProfilePopup;
