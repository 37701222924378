// store.js
import userReducer from './setting/userSlice';  
import tokenReducer from './setting/tokenSlice';  
import { useSelector } from 'react-redux';
import loadingReducer from './setting/loadingSlice';
import { configureStore } from '@reduxjs/toolkit';

// Function to save the Redux state to localStorage
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('reduxState', serializedState);
  } catch (e) {
    console.warn(e);
  }
}

// Function to load the Redux state from localStorage
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

// Load persisted state from localStorage
const persistedState = loadFromLocalStorage();

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    auth: userReducer,
    token: tokenReducer
  },
  preloadedState: persistedState, 
});

// Subscribe to the store to save state to localStorage on any change
store.subscribe(() => {
  saveToLocalStorage(store.getState());  // Save the entire Redux state
});

export function useUser() {
  return useSelector((state) => state.auth.user);  // Get user state
}

export function useToken() {
  return useSelector((state) => state.token);  // Get user state
}

export function useLoading(){
  return useSelector((state)=> state.loading);
}
