import React, { Fragment, useEffect } from 'react';
import { useSubheader } from '../components/SubheaderContext'; 

const Dashboard404 = () => {
  const { setLinks } = useSubheader();

  useEffect(() => {
    setLinks([    ]);

    // Cleanup on unmount
    return () => setLinks([]);
  }, [setLinks]);

  return (
    <Fragment>
      <style>
        h2{"{color:#E42021}"}
      </style>

      <section id="form" className="second-section section">
        <div className="container section-title" data-aos="fade-up">
          <h2>OPSS AUN NO HEMOS IMPLEMENTADO ESTO<br /></h2>
          <p>Regresa en un tiempo a ver si lo hemos hecho
          </p>
          <br/>
    

        </div>
        <div className="container text-center" data-aos="fade-up">
          <p>Queremos hacerlo pero nos falta tiempo</p>
        </div>
      </section>{/* End Formulario */}

    </Fragment>
  );
};

export default Dashboard404;