import React, { useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import {  Outlet, useNavigate } from 'react-router-dom';
import './Dashboard.css';  // Import the above CSS here
import { useUser } from '../store';

const Dashboard = ({ isAuthenticated }) => {

  const navigate = useNavigate();
  const user = useUser(); // Get user information from context

  useEffect(() => {
      if (!user) {
          // Redirect to login if user is not authenticated
          navigate('/login');
      } else if (!['Super', 'Manager',"User"].includes(user.role)) {
          // Redirect if the user doesn't have the appropriate role
          navigate('/unauthorized');
      }
      document.body.classList.add('dashboard');
     // dispatch(clearLoading());
      //debugger;

  }, [user, navigate ]);

  return (
    <div className="dashboard-main">
      <Sidebar isAuthenticated={isAuthenticated} />
      <div className="main-content dashboard">
          <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
  