// src/store/tokenSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null
};


const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
      setToken: (state, action) => {
        state.token = action.payload;
      },
      clearTokens: (state) => {
        state.accessToken = null;
        state.refreshToken = null;
      },
      setAccessToken: (state,action) => {
        state.token.accessToken=action.payload;
      },
      setRefreshToken: (state,action) => {
        state.token.refreshToken=action.payload;
      }
    }
  });

  export const { setToken, clearTokens,setAccessToken,setRefreshToken} =tokenSlice.actions;
  export default tokenSlice.reducer; 