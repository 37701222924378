import React, { useEffect, useState } from 'react';
import { Button, Table, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../utilities/axios_instance';
import './Reports.css';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const DueMembershipsList = () => {
  const apiUrl = process.env.REACT_APP_DUE_MEMBERSHIPS_API;
  const { t } = useTranslation();

  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Fetch the expiring memberships
    axiosInstance.get(`${apiUrl}/due_memberships`)
      .then(response => {
        if (response.data.memberships && response.data.memberships.length > 0) {
          setMemberships(response.data.memberships);
        } else {
          setMessage(response.data.message || t('due.no_due_memberships')); // Fallback to default message if no message from API
        }
        setLoading(false);
      })
      .catch(error => {
        setError(t('due.error_fetching_due_memberships')); // Translate error message
        setLoading(false);
      });
  }, [apiUrl, t]);

  const handleSendReminder = (memberID) => {
    
    axiosInstance.post(`${apiUrl}/send_reminder_email`, { memberID })
      .then(response => {
        if (response.data.success) {
          setMessage(t('due.reminder_email_sent_successfully'));
        } else {
          setError(t('due.failed_to_send_reminder_email'));
        }
      })
      .catch(error => {
        setError(t('due.error_sending_email'));
      });
  };
  var initialMonth = 0;
  const isDueThisMonth = (endMembership) => {
     // Check if this is the first time to set the month
     initialMonth=initialMonth===0?(new Date(endMembership)).getMonth():initialMonth
    return initialMonth===(new Date(endMembership)).getMonth();
  };

  const getDueClass= (membership) => {
    // Check if this is the first time to set the month
    if(membership.renewed===1)
    {
      return "renewed";
    }
    if (isDueThisMonth(membership.endMembership))
    {
      return "due-now";
    }
    return initialMonth+1===(new Date(membership.endMembership)).getMonth()?"":"safe-now";
 };

  if (loading) return <p>{t('due.loading')}</p>; // Translated loading text
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <div>
      <h1>{t('due.due_memberships')}</h1><br/>
      {message && <Alert variant="success" onClose={() => setMessage()} dismissible>{message}</Alert>}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t('due.member_id')}</th>
            <th>{t('due.num_socio')}</th>
            <th>{t('due.first_name')}</th>
            <th>{t('due.last_name')}</th>
            <th>{t('due.email')}</th>
            <th>{t('due.expiration_date')}</th>
            <th>{t('due.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {memberships.length > 0 ? (
            memberships.map((membership) => {
              const dueClass= getDueClass(membership);
              return (
                <tr
                  key={membership.memberId}
                  className={dueClass}
                >
                  <td>{membership.memberId}</td>
                  <td>{membership.numSocio}</td>
                  <td>{membership.firstname}</td>
                  <td>{membership.lastname}</td>
                  <td>{membership.email}</td>
                  <td>{new Date(membership.endMembership).toLocaleDateString()}</td>
                  <td className='text-center'>
                    {(membership.renewed===0 &&<Button
                      variant="primary"
                      onClick={() => handleSendReminder(membership.memberId)}
                      text={t('due.send_reminder')}
                      alt={t('due.send_reminder')}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>)}

                    {(membership.renewed===1 && <p>{t('due.renewed')}</p>)}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">{message || t('due.no_due_memberships')}</td>
            </tr>
          )}
        </tbody>

      </Table>
      <legend> <ul style={{listStyleType: "square"}}>
                <li className="due-now">{t("due.expires_soon")}</li>
                <li>{t("due.expires_next")}</li>
                </ul></legend>

    </div>
  );
};

export default DueMembershipsList;
