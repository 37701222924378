import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const SpinnerOverlay = () => {
  // Access the loading state from the Redux store
  const isLoading = useSelector((state) => state.loading.isLoading);

  return (
    <div
      className={`loading-overlay ${isLoading ? 'active' : ''}`} 
      style={{ display: isLoading ? 'flex' : 'none' }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

export default SpinnerOverlay;
