import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { useTranslation } from 'react-i18next';
import { useUser } from '../store';
import { useLocation } from 'react-router-dom'; // Add useLocation hook

const Sidebar = () => {
  const sidebarRef = useRef(null);
  const { t } = useTranslation();
  const user = useUser();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // State to track sidebar collapse
  const location = useLocation(); // Get the current location

  // Toggle sidebar collapse
  const toggleSidebar = () => {
    document.body.classList.toggle('toggle-sidebar');
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        document.body.classList.add('toggle-sidebar');
        setIsSidebarCollapsed(true);
      } else {
        document.body.classList.remove('toggle-sidebar');
        setIsSidebarCollapsed(false);
      }
    };

    handleResize();

    // Event listeners for resizing
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine if a route is active
  const isActiveRoute = (path) => location.pathname === path;

  // Determine if an accordion should be opened based on the route
  const isAccordionOpen = (paths) => paths.some(path => location.pathname.startsWith(path));

  useEffect(() => {
    // Ensure only the accordion with the active link is open
    const accordions = document.querySelectorAll('.accordion-collapse');
    accordions.forEach((accordion) => {
      const shouldOpen = accordion.contains(document.querySelector('.nav-content .active'));
      accordion.classList.toggle('show', shouldOpen);
    });
  }, [location.pathname]);

  return (
    <>
      <aside id="sidebar" className="sidebar" ref={sidebarRef}>
        {/* Sidebar Toggle Button */}
        <div className="sidebar-toggle-btn">
          <button className="btn" onClick={toggleSidebar}>
            <i className={`bi ${isSidebarCollapsed ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i>
          </button>
        </div>     
        <ul className="accordion accordion-flush sidebar-nav" id="sidebar-nav">
          <li className="accordion-item nav-item">
            <a 
              className={`accordion-header accordion-button nav-link ${isAccordionOpen(['/dashboard/new_member', '/dashboard/list_members', '/dashboard/member_card', '/dashboard/upload_photo', '/dashboard/birthdays']) ? 'show' : 'collapsed'}`}   
              id="members-header"
              data-bs-toggle="collapse" 
              data-bs-target="#members-nav" 
              aria-expanded={isAccordionOpen(['/dashboard/new_member', '/dashboard/list_members', '/dashboard/member_card', '/dashboard/upload_photo', '/dashboard/birthdays'])}
              aria-controls="members-nav" 
              href='./#'>
              <i className="bi bi-menu-button-wide"></i>
              <span>{t("sidebar.manage_members")}</span>
            </a>
            <ul id="members-nav" className={`nav-content accordion-collapse collapse ${isAccordionOpen(['/dashboard/new_member', '/dashboard/list_members', '/dashboard/member_card', '/dashboard/upload_photo']) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">
              <li><a className={isActiveRoute('/dashboard/new_member') ? 'active' : ''} href="/dashboard/new_member"><i className="bi bi-circle"></i><span>{t("sidebar.transient_create")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/list_members') ? 'active' : ''} href="/dashboard/list_members"><i className="bi bi-circle"></i><span>{t("sidebar.transient_list")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/member_card') ? 'active' : ''} href="/dashboard/member_card"><i className="bi bi-circle"></i><span>{t("sidebar.card")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/birthdays') ? 'active' : ''} href="/dashboard/birthdays"><i className="bi bi-circle"></i><span>{t("sidebar.birthdays")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/upload_photo') ? 'active' : ''} href="/dashboard/upload_photo"><i className="bi bi-circle"></i><span>{t("sidebar.photo")}</span></a></li>
            </ul>
          </li>

          <li className="accordion-item nav-item">
            <a 
              className={`accordion-header accordion-button nav-link ${isAccordionOpen(['/dashboard/attendance', '/dashboard/videos', '/dashboard/badges']) ? 'show' : 'collapsed'}`}  
              href="#events-nav"
              data-bs-toggle="collapse" 
              data-bs-target="#events-nav" 
              aria-expanded={isAccordionOpen(['/dashboard/attendance', '/dashboard/videos', '/dashboard/badges'])} 
              aria-controls="events-nav">
              <i className="bi bi-menu-button-wide"></i>
              <span>{t("sidebar.event_management")}</span>
            </a>
            <ul id="events-nav" className={`nav-content accordion-collapse collapse ${isAccordionOpen(['/dashboard/attendance', '/dashboard/videos', '/dashboard/badges']) ? 'show' : ''}`} 
                data-bs-toggle="collapse" data-bs-parent="#sidebar-nav">
              <li><a className={isActiveRoute('/dashboard/nextevent') ? 'active' : ''} href="/dashboard/nextevent"><i className="bi bi-circle"></i><span>{t("sidebar.nextevent")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/attendance') ? 'active' : ''} href="/dashboard/attendance"><i className="bi bi-circle"></i><span>{t("sidebar.attendance")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/videos') ? 'active' : ''} href="/dashboard/videos"><i className="bi bi-circle"></i><span>{t("sidebar.videos")}</span></a></li>
              <li><a className={isActiveRoute('/dashboard/badges') ? 'active' : ''} href="/dashboard/badges"><i className="bi bi-circle"></i><span>{t("sidebar.badges")}</span></a></li>
            </ul>
          </li>
          
          {['Super', 'Manager'].includes(user.role) && 
            <li className="nav-item accordion-item">
              <a 
                className={`accordion-header accordion-button nav-link ${isAccordionOpen(['/dashboard/members_list', '/dashboard/due_memberships', '/dashboard/errors']) ? 'show' : 'collapsed'}`}  
                href="#reports-nav"
                data-bs-toggle="collapse" 
                data-bs-target="#reports-nav" 
                aria-expanded={isAccordionOpen(['/dashboard/members_list', '/dashboard/due_memberships', '/dashboard/birthdays', '/dashboard/errors'])} 
                aria-controls="reports-nav">
                <i className="bi bi-journal-text"></i>
                <span>{t("sidebar.reports")}</span>
              </a>
              <ul id="reports-nav" className={`nav-content  accordion-collapse collapse ${isAccordionOpen(['/dashboard/members_list', '/dashboard/due_memberships', '/dashboard/errors']) ? 'show' : ''}`} 
                  data-bs-parent="#sidebar-nav">
                <li><a className={isActiveRoute('/dashboard/members_list') ? 'active' : ''} href="/dashboard/members_list"><i className="bi bi-circle"></i><span>{t("sidebar.member_report")}</span></a></li>
                <li><a className={isActiveRoute('/dashboard/due_memberships') ? 'active' : ''} href="/dashboard/due_memberships"><i className="bi bi-circle"></i><span>{t("sidebar.memberships")}</span></a></li>
                <li><a className={isActiveRoute('/dashboard/errors') ? 'active' : ''} href="/dashboard/errors"><i className="bi bi-circle"></i><span>{t("sidebar.errors")}</span></a></li>
                <li><a className={isActiveRoute('/dashboard/users') ? 'active' : ''} href="/dashboard/users"><i className="bi bi-circle"></i><span>{t("sidebar.users")}</span></a></li>
              </ul>
            </li>
          }
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
