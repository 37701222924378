import React from 'react';
import "../assets/css/main.css";

const Loader = () => {
   
    return (
      <div id="preloader"></div>
    )
}

export default Loader
