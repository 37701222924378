import React, { Fragment, useRef, useState, useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import '../../i18n';
import { useUser } from '../../store';
import axiosInstance from '../../utilities/axios_instance';


const dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';

const validateDNI = (dni) => {
  if (!/^\d{8}[A-Z]$/.test(dni)) {
    return false;
  }
  const number = dni.slice(0, 8);
  const letter = dni.slice(8, 9);
  const correctLetter = dniLetters[number % 23];
  return letter === correctLetter;
};

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (`0${d.getMonth() + 1}`).slice(-2); // Adding 1 because getMonth() is zero-based
  const day = (`0${d.getDate()}`).slice(-2);

  return `${year}-${month}-${day}`;
};

const MemberForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signaturePadRef = useRef(null);
  const { id } = useParams();
  const [dni, setDni] = useState('');
  const [showDniAlert, setShowDniAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [resultSuccess, setResultSuccess] = useState(false);
  const [formData, setFormData] = useState({
    alias: '',
    firstname: '',
    lastname: '',
    dni: '',
    email: '',
    phone: '',
    category: 'Socio numerario',
    gender: '',
    postalcode: '',
    city: '',
    province: '',
    signature: '',
    meetupid: '',
    birthdate: '',
    address: '',
  });

  const apiUrl = process.env.REACT_APP_TRANSIENT_MEMBERS_API;
  const user = useUser(); // Get user information from context

  useEffect(() => {
    if (id) {
      // Fetch existing member data if 'id' is present
      axiosInstance.get(`${apiUrl}/get_member`, { params: { id } })
        .then(response => {
          if (response.data.success) {
            const member = response.data.member;
            setFormData({
              alias: member.alias || '',
              firstname: member.firstname || '',
              lastname: member.lastname || '',
              dni: member.dni || '',
              email: member.email || '',
              phone: member.phone || '',
              category: member.category || 'Socio numerario',
              gender: member.gender || 'H',
              postalcode: member.postalCode || '00000',
              city: member.city || '',
              province: member.province || '',
              signature: member.signature || '',
              meetupid: member.meetupId || '',
              birthdate: formatDate(member.birthdate) || '',
              address: member.address || '',
            });
            setDni(member.dni || '');
            if (signaturePadRef.current && member.signature) {
              signaturePadRef.current.fromDataURL(member.signature);
            }
          } else {
            setResultMessage(t('new_member.member_not_found'));
            setResultSuccess(false);
          }
        })
        .catch(error => {
          console.error('Error fetching member data:', error);
          setResultMessage(t('new_member.form_error') + error.message);
          setResultSuccess(false);
        });
    }
  }, [id, apiUrl, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (signaturePadRef.current.isEmpty()) {
      setResultMessage(t('new_member.provide_signature'));
      setResultSuccess(false);
      setIsSubmitting(false);
      window.scrollTo(0, 0);
      return;
    }

    const dniValue = dni;
    if (!validateDNI(dniValue)) {
      setShowDniAlert(true);
      setIsSubmitting(false);
      window.scrollTo(0, 0);
      return;
    }

    submitForm(e.target);
  };

  const submitForm = (form) => {
    const formData = new FormData(form);
    formData.append('signature', signaturePadRef.current.toDataURL());

    const jsonFormData = JSON.stringify(Object.fromEntries(formData));
    const endpoint = id ? `${apiUrl}/update_member` : `${apiUrl}/new_member`;
    // Define the headers object
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    };

    // Add Authorization header only if user and user.token are defined
    if (user && user.token) {
      headers.Authorization = `Bearer ${user.token}`;
    }

    axiosInstance.put(endpoint, jsonFormData, {
      headers: headers,
    })
      .then(response => {
        if (response.data.success) {
          const submittedData = Object.fromEntries(formData.entries());
          const formattedData = JSON.stringify(submittedData, null, 2);
          setResultMessage(t(id ? 'new_member.member_update_success' : 'new_member.form_update_success') + '\n' + formattedData);
          setResultSuccess(true);
          if (!id) {
            handleClearForm();
          } else {
            navigate("/dashboard/list_members");
          }
        } else {
          setResultMessage(t('new_member.form_error') + response.data.message);
          setResultSuccess(false);
        }
      })
      .catch(error => {
        console.error('There was an error!', error);
        setResultMessage(t('new_member.form_error') + error.message);
        setResultSuccess(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        window.scrollTo(0, 0);
      });
  };

  const handleClearForm = () => {
    document.getElementById('memberForm').reset();
    signaturePadRef.current.clear();
    setDni('');
    setShowDniAlert(false);
    setFormData({
      alias: '',
      firstname: '',
      lastname: '',
      dni: '',
      email: '',
      phone: '',
      category: 'Socio numerario',
      gender: '',
      postalcode: '',
      city: '',
      province: '',
      signature: '',
      meetupid: '',
      birthdate: '',
      address: '',
    });
  };

  const closeAlert = () => {
    setResultMessage('');
    setShowDniAlert(false);
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: 'white' }}>
        
        <div className="container mt-5">
        <div className="pagetitle">
          <h1>{id ? t('new_member.edit_member') : t('new_member.new_member')}</h1>
          <br/>
        </div>
          {showDniAlert && (
            <div className="alert alert-warning alert-dismissible" role="alert">
              <button type="button" className="btn-close" onClick={closeAlert}></button>
              {t('new_member.dni_incorrect')}
              <button className="btn btn-warning ms-3" onClick={() => { setShowDniAlert(false); submitForm(document.getElementById('memberForm')) }}>{t('new_member.send_anyway')}</button>
              <button className="btn btn-secondary ms-3" onClick={closeAlert}>{t('new_member.cancel')}</button>
            </div>
          )}
          {resultMessage && (
            <div className={`alert ${resultSuccess ? 'alert-success' : 'alert-danger'} alert-dismissible`} role="alert">
              <button type="button" className="btn-close" onClick={closeAlert}></button>
              <pre>{resultMessage}</pre>
            </div>
          )}
          <form onSubmit={handleSubmit} id="memberForm">
            <input type='hidden' name='id' id='id' value={id || ''} />
            
            {/* Alias Input */}
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="alias" className="form-label">* {t('new_member.alias')}</label>
                <input type="text" className="form-control" id="alias" name="alias" value={formData.alias} onChange={(e) => setFormData({ ...formData, alias: e.target.value })} required />
              </div>

              
              <div className="col-md-4">
                <label htmlFor="dni" className="form-label">* {t('new_member.dni')}</label>
                <input type="text" className="form-control" id="dni" name="dni" value={dni} onChange={(e) => setDni(e.target.value)} required />
              </div>
              {/* Meetup ID Input */}
              <div className="col-md-4">
                <label htmlFor="meetupid" className="form-label">{t('new_member.meetup_id')}</label>
                <input type="text" className="form-control" id="meetupid" name="meetupid" value={formData.meetupid} onChange={(e) => setFormData({ ...formData, meetupid: e.target.value })} />
              </div>

            </div>

            {/* First Name and Last Name Inputs */}
            <div className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="firstname" className="form-label">* {t('new_member.firstname')}</label>
                <input type="text" className="form-control" id="firstname" name="firstname" value={formData.firstname} onChange={(e) => setFormData({ ...formData, firstname: e.target.value })} required />
              </div>
              <div className="col-md-3">
                <label htmlFor="lastname" className="form-label">* {t('new_member.lastname')}</label>
                <input type="text" className="form-control" id="lastname" name="lastname" value={formData.lastname} onChange={(e) => setFormData({ ...formData, lastname: e.target.value })} required />
              </div>
              {/* Gender Selection */}
              <div className="col-md-2">
                <label htmlFor="gender" className="form-label">* {t('new_member.gender')}</label>
                <select className="form-select" id="gender" name="gender" value={formData.gender} onChange={(e) => setFormData({ ...formData, gender: e.target.value })} required>
                  <option value="H">{t('new_member.gender_m')}</option>
                  <option value="M">{t('new_member.gender_f')}</option>
                  <option value="O">{t('new_member.gender_o')}</option> 
                </select>
              </div>

              {/* Birthdate Input */}
              <div className="col-md-4">
                <label htmlFor="birthdate" className="form-label">{t('new_member.birthdate')}</label>
                <input type="date" className="form-control" id="birthdate" name="birthdate" value={formData.birthdate} onChange={(e) => setFormData({ ...formData, birthdate: e.target.value })} required />
              </div>
            </div>

            {/* DNI and Email and category Inputs */}
            <div className="row mb-3">
             
              <div className="col-md-4">
                <label htmlFor="email" className="form-label">*{t('new_member.email')}</label>
                <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone" className="form-label">{t('new_member.phone')}</label>
                <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
              </div>
              <div className="col-md-4">
                <label htmlFor="category" className="form-label">{t('new_member.category')}</label>
                <select className="form-select" id="category" name="category" value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })} required>
                  <option value="Socio numerario">{t('new_member.category_numerario')}</option>
                  <option value="Simpatizante">{t('new_member.category_sympathiser')}</option>
                  <option value="Socio becado">{t('new_member.category_granted')}</option>
                  <option value="Socio espectador">{t('new_member.category_public')}</option>
                </select>
              </div>
            </div>

            {/* Address and Category */}
            <div className="row mb-3">
              <div className="col-md-4">
                <label htmlFor="address" className="form-label">{t('new_member.address')}</label>
                <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={(e) => setFormData({ ...formData, address: e.target.value })} required />
              </div>
              <div className="col-md-3">
                <label htmlFor="city" className="form-label">{t('new_member.city')}</label>
                <input type="text" className="form-control" id="city" name="city" value={formData.city} onChange={(e) => setFormData({ ...formData, city: e.target.value })} />
              </div>
              
             
              <div className="col-md-3">
                <label htmlFor="province" className="form-label">{t('new_member.province')}</label>
                <input type="text" className="form-control" id="province" name="province" value={formData.province} onChange={(e) => setFormData({ ...formData, province: e.target.value })} />
              </div>
              <div className="col-md-2">
                <label htmlFor="postalcode" className="form-label">{t('new_member.postalcode')}</label>
                <input type="text" className="form-control" id="postalcode" name="postalcode" value={formData.postalcode} onChange={(e) => setFormData({ ...formData, postalcode: e.target.value })} />
              </div>
            </div>

            {/* Signature */}
            <div className="mb-3 text-center">
              <label htmlFor="signature" className="form-label d-block">{t('new_member.signature')}</label>
              <div className="d-flex justify-content-center align-items-start">
                <SignaturePad ref={signaturePadRef} canvasProps={{ className: ' signatureCanvas signature-pad' }} />
                <button type="button" className="btn btn-secondary ms-3 mt-0 align-self-start" onClick={() => signaturePadRef.current.clear()}>{t('Clear')}</button>
              </div>
            </div>
         
            <div className="mb-3 text-center">
 
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? t('new_member.submitting') : t('new_member.submit')}
            </button>
            <button type="button" className="btn btn-secondary ms-2" onClick={handleClearForm}>
              {t('new_member.clear_form')}
            </button>
            </div>
          </form>
          <iframe width='100%' src="https://pianomeetups.com/members/consent.html"></iframe>
        </div>
      
      </div>
    </Fragment>
  );
};

export default MemberForm;
