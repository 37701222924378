import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            parse: function (data,t) {
              return JSON.parse(data);
          },
        },
        async:true,
        react: {
          useSuspense: true,
        },
        interpolation:{
          escapeValue:false
        }

      }, (err,t) => {
         console.log(t("dontHaveAccount"));
         console.log("INIT FINISHED")
         console.log(err);
        });


export default i18n;
