// Logout.js

import React from 'react';

import { useNavigate } from 'react-router-dom'; // Import useHistory hook

const Logout = ({ setIsAuthenticated }) => {
    const navigate = useNavigate();

  const handleLogout = () => {
    // Perform logout actions here
    setIsAuthenticated(false); // Example: Clear authentication state
    window.localStorage.setItem("auth state", JSON.stringify(false)); // <-- stringify
    navigate('/');
};

  return (
    <div>
      <h2>Logout</h2>
      <p>Are you sure you want to logout?</p>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Logout;
